import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import HttpHelper from "../../../../app/helpers/HttpHelper";
import star_icon from "../../../../res/images/icons/star-icon-orange.png";
import star_icon_blue from "../../../../res/images/icons/star-icon-blue.svg";
import ReviewsIcon from "@mui/icons-material/Reviews";
import add_user_icon from "../../../../res/images/plan-icons/add-user-orange.svg";
import "../../settings.css";
import { ROUTES } from "../../../../app/routing";
import { ACCOUNT_PLAN } from "../../../../app/constants";

import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  LinearProgress,
} from "@mui/material";

import Loader from "../../../../components/loader/Loader";
import STypography from "../../../../components/SMui/STypography";
import IntercomHelper from "../../../../app/helpers/IntercomHelper";
import moment from "moment-timezone";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Select, { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { AccountDataManagerContext } from "../../../../dataManagers/accountDataManager";
import { billingService } from "../../../../app/services/billing.service";

class OverviewTab extends React.Component {
  static contextType = AccountDataManagerContext;

  constructor(props) {
    super(props);

    this.state = {
      account: props.account,
      usersStatus: "success",
      planReached: null,
      dataLoaded: false,
      planList: null,
      subscriptionsList: null,
      current_plan: null,
      seatNumber: props.account.quantity,
      changePlanLoading: false,
      changeSeatLoading: false,
    };

    this.upgradePlan = this.upgradePlan.bind(this);
    this.upgradePlanSeat = this.upgradePlanSeat.bind(this);
    this.displayToast = this.displayToast.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;

    billingService.getPlan(
      (response) => {
        let planOptions = response.data?.PLAN.flatMap((plan) =>
          plan.prices
            .filter((price) => price.enabled) // Filtrer les prix activés
            .map((price) => ({
              name: plan.name, // Nom du plan
              level: plan.extra_metadata.level, // Niveau du plan
              stripePriceId: price.stripe_price_id, // ID Stripe du prix
              interval: price.interval, // Intervalle (month/year)
              unitAmount: price.unit_amount, // Montant unitaire
              currency: price.currency, // Devise
            }))
        );

        let current_plan = response.subscriptions?.filter(
          (s) => s.type === "PLAN"
        );

        if (current_plan.length > 0) {
          current_plan = current_plan[0];
        } else {
          current_plan = null;
        }

        this.setState({
          dataLoaded: true,
          planOptions: planOptions,
          planReached: planOptions[0]?.stripePriceId,
          subscriptionsList: response.subscriptions,
          current_plan: current_plan,
        });
      },
      (error) => {
        this.setState({
          dataLoaded: true,
        });
        this.displayToast(HttpHelper.getErrorMessage(error));
      }
    );
  }

  displayToast(message, autoDismiss = true) {
    this.props.addToast(message, {
      type: "error",
      autoDismiss: autoDismiss,
    });
  }

  upgradePlan() {
    if (!this.state.planReached) {
      return; //TODO: set error
    }
    const current_plan = this.state.current_plan;

    const maxUsers =
      (this.state.account.nb_user ?? 0) > (this.state.account.quantity ?? 0)
        ? this.state.account.nb_user ?? 0
        : this.state.account.quantity ?? 0;

    this.setState({ changePlanLoading: true });
    if (this.state.planReached === "Cancel") {
      billingService.cancelSubscribtion(
        current_plan?.subscription_id,
        (response) => {
          if (response.status === "success") {
            window.location.replace(response.url);
          } else {
            this.displayToast("Unknown error occurred");
          }
          this.setState({ changePlanLoading: false });
        },
        (error) => {
          this.displayToast(HttpHelper.getErrorMessage(error));
          this.setState({ changePlanLoading: false });
        }
      );
    } else if (!current_plan) {
      billingService.createSubscribtion(
        this.state.planReached,
        maxUsers,
        (response) => {
          if (response.status === "success") {
            window.location.replace(response.url);
          } else {
            this.displayToast("Unknown error occurred");
          }
          this.setState({ changePlanLoading: false });
        },
        (error) => {
          this.displayToast(HttpHelper.getErrorMessage(error));
          this.setState({ changePlanLoading: false });
        }
      );
    } else {
      billingService.updateSubscribtion(
        this.state.planReached,
        current_plan?.subscription_id,
        maxUsers,
        (response) => {
          if (response.status === "success") {
            window.location.replace(response.url);
          } else {
            this.displayToast("Unknown error occurred");
          }
          this.setState({ changePlanLoading: false });
        },
        (error) => {
          this.displayToast(HttpHelper.getErrorMessage(error));
          this.setState({ changePlanLoading: false });
        }
      );
    }
  }

  upgradePlanSeat() {
    const current_plan = this.state.current_plan;

    if (!current_plan || current_plan.length === 0) {
      return;
    }

    this.setState({ changeSeatLoading: true });

    billingService.updateSubscribtion(
      current_plan.stripe_price_id,
      current_plan.subscription_id,
      this.state.seatNumber,
      (response) => {
        if (response.status === "success") {
          window.location.replace(response.url);
        } else {
          this.displayToast("Unknown error occurred");
        }
        this.setState({ changeSeatLoading: false });
      },
      (error) => {
        this.displayToast(HttpHelper.getErrorMessage(error));
        this.setState({ changeSeatLoading: false });
      }
    );
  }

  render() {
    const { t, account } = this.props;

    const totalReviews = this.context.accountDataManagerState.totalReviews;
    const maxReviews = this.context.accountDataManagerState.maxReviews;
    const reviewStatus =
      this.context.accountDataManagerState.reviewsQuotasStatus;

    const planKey = this.context.accountDataManagerState.plan;
    const planFreq = this.state.current_plan?.interval;

    const reniewDate = moment
      .utc(account.expiration_date)
      .local()
      .format("MMMM DD, YYYY");
    let reniewText = null;
    if (account.expiration_date) {
      reniewText = `Your subscription will renew on ${reniewDate}`;
    }
    const planText = (
      <>
        You are currently on the{" "}
        <span style={{ fontWeight: 600 }}>{planKey}</span>, billed per{" "}
        {planFreq}
      </>
    );

    let title = "";
    let subtitle = "";
    switch (planKey) {
      case ACCOUNT_PLAN.FREE:
        title = t(
          "Upgrade now to Pro Plan to get Deeper Insights and Advanced Analytics!"
        );
        subtitle = t(
          "Manage 3 projects, access detailed analytics, and create 1 custom classe. Collect data from all Sensia sources and easily import surveys. Elevate your marketing intelligence today!"
        );
        break;
      case ACCOUNT_PLAN.PRO:
        title = t(
          "Upgrade now to Supercharge your Analytics and Collaboration!"
        );
        subtitle = t(
          "Manage 5 projects, access even deeper analytics, and create up to 4 custom classes. collaborate with up to 3 users. Elevate your marketing capabilities today!"
        );
        break;
      case ACCOUNT_PLAN.BUSINESS:
        title = t(
          "Upgrade now to Unlock Limitless Potential and Full Integration!"
        );
        subtitle = t(
          "enjoy unlimited projects, users, and custom classes. Access 500 data sources per project, including advanced integrations with cloud apps, eCommerce reviews, and more."
        );
        break;
      case ACCOUNT_PLAN.ENTERPRISE:
        title = t("Maximize the Full Potential of your Enterprise Plan");
        subtitle = t(
          "With Sensia Enterprise, enjoy unlimited projects, users, custom classes, and advanced integrations. Need help or want to optimize further? We're here for you!"
        );
        break;
    }

    const quotasPercent = (totalReviews / maxReviews ?? 1) * 100;

    const totalUsers = this.state.account.nb_user;
    const maxUsers = this.state.account.quantity;

    const usersPercent = (totalUsers / maxUsers ?? 1) * 100;

    const seatNumber = this.state.seatNumber;
    return (
      <Fragment>
        {/* PLAN */}
        <Box
          sx={{
            display: "flex",
            padding: "25px",
            flexDirection: "column",
            alignItems: "flex-start",
            borderRadius: "9px",
            background: "#FFF",
            boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "14px",
                alignSelf: "stretch",
                justifyContent: "space-between",
              }}
            >
              <STypography
                sx={{
                  fontSize: "24px",
                  fontWeight: 700,
                  gap: "3px",
                }}
              >
                {`${planKey} plan`}
              </STypography>

              {planKey !== ACCOUNT_PLAN.ENTERPRISE && (
                <STypography
                  className="light-link-blue"
                  sx={{
                    color: " #306ed6",
                    fontSize: "15px",
                    fontWeight: 500,
                    textAlign: "end",
                  }}
                  onClick={() => this.props.history.push(ROUTES.PLAN_CHOOSE)}
                >
                  {t("View all plan")}
                </STypography>
              )}
            </Box>
            {planKey != ACCOUNT_PLAN.FREE && (
              <>
                <STypography
                  sx={{
                    flex: "1 0 0",
                    textAlign: "start",
                    color: "#7F7F7F",
                    fontWeight: 400,
                    mt: "22px",
                  }}
                >
                  {planText}
                </STypography>
                {reniewText && (
                  <STypography
                    sx={{
                      flex: "1 0 0",
                      textAlign: "start",
                      color: "#7F7F7F",
                      fontWeight: 400,
                      mt: "5px",
                    }}
                  >
                    {reniewText}
                  </STypography>
                )}
              </>
            )}
          </Box>
          {planKey !== ACCOUNT_PLAN.ENTERPRISE &&
            planKey !== ACCOUNT_PLAN.FREE && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "24px",
                    alignSelf: "stretch",
                    borderRadius: "6px",
                    mt: "10px",
                  }}
                >
                  <Select
                    placeholder="Upgrade your plan"
                    indicator={<KeyboardArrowDown />}
                    value={this.state.planReached}
                    onChange={(e, newValue) =>
                      this.setState({ planReached: newValue })
                    }
                    sx={{
                      borderRadius: "4px",
                      width: "100%",
                      maxWidth: "299px",
                      background: "transparent",
                      fontFamily: "Inter",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      color: "#000",
                      [`& .${selectClasses.indicator}`]: {
                        transition: "0.2s",
                        [`&.${selectClasses.expanded}`]: {
                          transform: "rotate(-180deg)",
                        },
                      },
                    }}
                  >
                    {this.state.planOptions?.map((plan) => {
                      return (
                        <Option
                          key={plan.stripePriceId}
                          value={plan.stripePriceId}
                        >{`${
                          planKey.toUpperCase() == plan.level
                            ? "Switch to"
                            : "Upgrade to"
                        } ${plan.name}${
                          plan.interval == "year" ? " (Annual)" : ""
                        }`}</Option>
                      );
                    })}
                    <Option value={"Cancel"}>{t("Cancel Plan")}</Option>
                  </Select>
                  {!this.state.changePlanLoading && (
                    <Button
                      variant="contained"
                      disabled={
                        !this.state.planReached ||
                        (totalReviews > 500 &&
                          this.state.planReached === "Cancel")
                      }
                      sx={{ borderRadius: "6px", textTransform: "none" }}
                      onClick={() => this.upgradePlan()}
                    >
                      {t("Change Plan")}
                    </Button>
                  )}
                  {this.state.changePlanLoading && (
                    <Box>
                      <Loader />
                    </Box>
                  )}
                </Box>
                {totalReviews > 500 && this.state.planReached === "Cancel" && (
                  <STypography
                    sx={{ color: "#E04F77", fontWeight: 400, mt: "20px" }}
                  >
                    {t(
                      `You’ve exceeded the 500 review limit for the Free plan. Please free up space to proceed`
                    )}
                  </STypography>
                )}
              </>
            )}
          {planKey === ACCOUNT_PLAN.FREE && (
            <Box
              sx={{
                mt: "22px",
                display: "flex",
                padding: "15px",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
                alignSelf: "stretch",
                borderRadius: "6px",
                background: "#FDE7D4",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  gap: "15px",
                }}
              >
                <STypography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {t("Upgrade now for Deeper Insights and Advanced Analytics!")}
                </STypography>
                <STypography
                  sx={{
                    color: "#7F7F7F",
                    fontWeight: 400,
                  }}
                >
                  {t(
                    "Unlock more AI customization, extra review storage, and powerful sharing options. Collect data from all Sensia sources and import surveys with ease. Elevate your marketing intelligence today!"
                  )}
                </STypography>
              </Box>
              <Button
                sx={{
                  mt: "5px",
                  borderRadius: "6px",
                  background:
                    "linear-gradient(90deg, #F9B16E 0%, #F68080 100%)",
                  display: "flex",
                  width: "126px",
                  height: "26px",
                }}
                onClick={() => this.props.history.push(ROUTES.PLAN_CHOOSE)}
              >
                <STypography
                  sx={{
                    color: "#FFF",
                    fontSize: "15px",
                    textTransform: "none",
                  }}
                >
                  {t("Upgrade")}
                </STypography>
              </Button>
            </Box>
          )}
          {planKey === ACCOUNT_PLAN.ENTERPRISE && (
            <Box
              sx={{
                mt: "22px",
                display: "flex",
                padding: "15px",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
                alignSelf: "stretch",
                borderRadius: "6px",
                background: "#E2E2FB",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  gap: "15px",
                }}
              >
                <STypography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {t("Maximize the Full Potential of your Enterprise Plan!")}
                </STypography>
                <STypography
                  sx={{
                    color: "#000",
                    fontWeight: 400,
                  }}
                >
                  {t(
                    "With Sensia Enterprise, enjoy unlimited projects, users, custom classes, and advanced integrations. Need help or want to optimize further? We're here for you!"
                  )}
                </STypography>

                <Button
                  sx={{
                    mt: "5px",
                    borderRadius: "6px",
                    background: "#7879F1",
                    display: "flex",
                    width: "208px",
                    height: "30px",
                    "&:hover": {
                      background: "#6B6DF3",
                    },
                  }}
                  onClick={() =>
                    IntercomHelper.trackEvent("contact-success-team")
                  }
                >
                  <STypography
                    sx={{
                      color: "#FFF",
                      fontSize: "15px",
                      textTransform: "none",
                    }}
                  >
                    {t("Contact our success team")}
                  </STypography>
                </Button>
              </Box>
            </Box>
          )}
        </Box>

        {/* USERS */}
        <Box
          sx={{
            mt: "26px",
            display: "flex",
            padding: "25px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "25px",
            borderRadius: "9px",
            background: "#FFF",
            boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "50px",
                height: "50px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "6px",
                background: "#FDE7D4",
              }}
            >
              <img src={add_user_icon} height={"27px"} />
            </Box>

            <STypography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {t("User")}
            </STypography>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                padding: "5px 0px",
                alignItems: "flex-start",
                gap: "17px",
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <STypography
                sx={{
                  fontSize: "24px",
                  fontWeight: 700,
                }}
              >
                {totalUsers?.toLocaleString()}{" "}
                {planKey === ACCOUNT_PLAN.FREE && (
                  <span className="font-size-15 font-weight-600">used</span>
                )}
                {planKey !== ACCOUNT_PLAN.FREE && (
                  <span className="font-size-15 font-weight-600">
                    of {maxUsers?.toLocaleString()} used
                  </span>
                )}
              </STypography>

              <LinearProgress
                sx={{
                  width: "100%",
                  height: "10px",
                  backgroundColor: "#27C98699",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor:
                      usersPercent > 100 && planKey !== ACCOUNT_PLAN.FREE
                        ? "#F78F64"
                        : "#27C986",
                  },
                  borderRadius: 5,
                }}
                variant="determinate"
                value={usersPercent > 100 ? 100 : usersPercent}
              />
            </Box>
            {planKey !== ACCOUNT_PLAN.FREE &&
              planKey !== ACCOUNT_PLAN.ENTERPRISE && (
                <>
                  <STypography
                    sx={{ mt: "37px", fontSize: "15px", fontWeight: 400 }}
                  >
                    {t(
                      "Add users to strengthen your team and speed up projects. More users mean more collaboration and efficiency. Boost your team now!"
                    )}
                  </STypography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: "20px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <STypography sx={{ fontSize: "15px" }}>
                        {t("Adjust Seat")}
                      </STypography>
                      <ButtonGroup
                        variant="outlined"
                        aria-label="Basic button group"
                        sx={{
                          borderRadius: "4px",
                          border: "1px solid #D2D2D2",
                        }}
                      >
                        <Button
                          disabled={seatNumber <= 1}
                          onClick={() => {
                            let newSeatNumber = seatNumber - 1;
                            if (newSeatNumber <= 0) {
                              newSeatNumber = 1;
                            }
                            this.setState({
                              seatNumber: newSeatNumber,
                              usersStatus:
                                newSeatNumber < totalUsers
                                  ? "error"
                                  : "success",
                            });
                          }}
                          sx={{ border: "1px solid #D2D2D2" }}
                        >
                          -
                        </Button>
                        <Divider orientation="vertical" flexItem />
                        <STypography
                          sx={{
                            fontSize: "15px",
                            width: "34px",
                            textAlign: "center",
                            alignContent: "center",
                            border: "1px solid #D2D2D2",
                          }}
                        >
                          {seatNumber}
                        </STypography>
                        <Divider orientation="vertical" flexItem />
                        <Button
                          onClick={() => {
                            const newSeatNumber = seatNumber + 1;
                            this.setState({
                              seatNumber: newSeatNumber,
                              usersStatus:
                                newSeatNumber < totalUsers
                                  ? "error"
                                  : "success",
                            });
                          }}
                          sx={{ border: "1px solid #D2D2D2" }}
                        >
                          +
                        </Button>
                      </ButtonGroup>
                    </Box>
                    {!this.state.changeSeatLoading && (
                      <Button
                        variant="contained"
                        disabled={
                          maxUsers === seatNumber ||
                          this.state.usersStatus === "error"
                        }
                        sx={{ borderRadius: "6px", textTransform: "none" }}
                        onClick={() => this.upgradePlanSeat()}
                      >
                        {seatNumber >= maxUsers
                          ? t("Add seat")
                          : t("Remove seat")}
                      </Button>
                    )}
                    {this.state.changeSeatLoading && (
                      <Box>
                        <Loader />
                      </Box>
                    )}
                  </Box>
                  {this.state.usersStatus == "error" && (
                    <STypography
                      sx={{ mt: "20px", fontWeight: 400, color: "#E04F77" }}
                    >
                      {t(
                        "Cannot reduce seats below active users. Please deactivate users to proceed."
                      )}
                    </STypography>
                  )}
                </>
              )}
            {planKey === ACCOUNT_PLAN.ENTERPRISE && (
              <STypography
                sx={{
                  mt: "20px",
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              >
                {t("Need more seat ?")}{" "}
                <span
                  onClick={() =>
                    IntercomHelper.trackEvent("contact-success-team")
                  }
                  style={{
                    cursor: "pointer",
                    color: "#3A79E6",
                    fontSize: "15px",
                    textTransform: "none",
                  }}
                >
                  {t("Contact sales")}
                </span>
              </STypography>
            )}
          </Box>
        </Box>

        {/* REVIEWS */}
        <Box
          sx={{
            mt: "26px",
            display: "flex",
            padding: "25px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "25px",
            borderRadius: "9px",
            background: "#FFF",
            boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "50px",
                height: "50px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "6px",
                background: "#FDE7D4",
              }}
            >
              <ReviewsIcon sx={{ height: "27px", color: "#FF9378" }} />
            </Box>

            <STypography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {t("Reviews")}
            </STypography>
          </Box>

          <Box
            sx={{
              display: "flex",
              padding: "5px 0px",
              alignItems: "flex-start",
              gap: "17px",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <STypography
              sx={{
                fontSize: "24px",
                fontWeight: 700,
              }}
            >
              {totalReviews?.toLocaleString()}{" "}
              <span className="font-size-15 font-weight-600">
                of {maxReviews?.toLocaleString()} used
              </span>
            </STypography>

            <LinearProgress
              sx={{
                width: "100%",
                height: "10px",
                backgroundColor: "#27C98699",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: quotasPercent > 100 ? "#F78F64" : "#27C986",
                },
                borderRadius: 5,
              }}
              variant="determinate"
              value={quotasPercent > 100 ? 100 : quotasPercent}
            />
          </Box>

          <Box sx={{ width: "100%" }}>
            {reviewStatus == "error" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <InfoOutlinedIcon
                  sx={{ width: "18px", height: "18px", color: "#000" }}
                />
                <STypography sx={{ fontWeight: 400 }}>
                  {t(
                    "Storage Limit Exceeded. Upgrade to continue exploring and get the latest updates."
                  )}
                </STypography>
              </Box>
            )}
          </Box>
        </Box>

        {/* IA */}
        <Box
          sx={{
            mt: "26px",
            display: "flex",
            padding: "25px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            borderRadius: "9px",
            background: "#FFF",
            boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "50px",
                  height: "50px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  background: "#FDE7D4",
                }}
              >
                <img alt="Reviews" height="25px" src={star_icon} />
              </Box>
              <STypography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {t("IA")}
              </STypography>
            </Box>
            <Box
              className="light-link-blue"
              onClick={() => IntercomHelper.trackEvent("add_IA")}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <STypography
                className="light-link-blue"
                sx={{ color: "#3A79E6", fontSize: "15px", fontWeight: 500 }}
              >
                {t("Add New IA")}
              </STypography>
            </Box>
          </Box>

          {account.product_categories.map((category) => {
            return (
              <Box
                key={category.title}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
              >
                <img src={star_icon_blue} alt="categories" height="15px" />

                <STypography
                  sx={{
                    fontWeight: 700,
                    letterSpacing: "0.15px",
                  }}
                >
                  {category.title}
                </STypography>
              </Box>
            );
          })}
          <STypography sx={{ fontWeight: 400 }}>
            {t(
              "Add AI to enhance your analyses. More AI means more detailed and personalized insights. Amplify your marketing intelligence today!"
            )}
          </STypography>
        </Box>

        <div className="padding-top-50" />
      </Fragment>
    );
  }
}

export default withTranslation()(OverviewTab);
